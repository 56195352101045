@import url('https://fonts.googleapis.com/css2?family=Forum&family=Jost:wght@100..900&display=swap');

@keyframes fontPulse {
  0% {
    font-weight: 100;

  }

  100% {
    font-weight: 900;
  }
}
