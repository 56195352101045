/* You can add global styles to this file, and also import other style files */
@use "fonts";
// @use "sass:math";
$rootSelector: ":root";

@use "@chladog/wemarry-shared/scss/styles" with (
  $rootSelector: $rootSelector
);

#{$rootSelector} {
  --color-primary: #f8eee2;
  --color-primary-rgba: rgba(248 238 226, var(--color-primary-alpha));
  --color-accent: #0c948c;
  --color-accent-hover: #0eb0a7;
  --color-accent-contrast: #ffffff;
  --header: #fff;
  --header-height: calc(var(--viewport-border-width, 0rem) + 2.875rem);
  --viewport-border-width: 1.15rem;
  --space-x: 1rem;
  --space-y: 1rem;
  --space-row-y: 4rem;
  --space-footer-y: 4rem;
  --space-input: 1rem;
  --container: 85.25rem;
  --columns-min-width: 15.625rem;
  --columns-flex-wrap: wrap;
  --columns-flex-justify-content: flex-start;
  --columns-flex-align-items: stretch;
  --button-ghost-hover-background: rgba(0, 0, 0, 0.05);
  --button-line-height: 1.25rem;
  --anim-speed: 250ms;
  --wm-group-gap: calc(var(--space-button) / 2) calc(var(--space-button) / 2);
  --wm-menu-text-transform: uppercase;
  --wm-v-menu-gap: 0;
  --wm-h-menu-gap: 0;

  --size-content-1: 100%;
  --size-content-2: 100%;
  --size-content-3: 100%;
  --size-header-1: 100%;
  --size-header-2: 100%;
  --size-header-3: 100%;

  --mdc-snackbar-container-color: var(--header);

  --line-height: 1.75;

  --button-gap: calc(var(--space-button, 1rem) / 2)
    calc(var(--space-button) / 2);

  @media (max-width: 37.5rem) {
    --viewport-border-width: 0.1875rem;
    --space-x: 0.5rem;
    --space-row-y: 2rem;
  }

  --viewport-height: calc(
    100 * var(--svh, 1vh) - var(--header-height) - var(
        --viewport-border-width,
        0rem
      )
  );
  --font-family: Jost, sans-serif;
  --font-weight: 300;
  --h-font-family: Forum, serif;
  --h-font-weight: 400;

  --button-font-weight-hover-offset: 400;
  --flexbox-align: stretch;
}

// @for $i from 1 through 8 {
//   .cols-#{$i} {
//     --grid-template-columns: columns($i);
//   }
// }

:root {
  font-family: var(--font-family);
}

::selection {
  color: var(--color-accent-contrast);
  background-color: var(--color-accent);
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  // stroke-width: 0;
  // stroke: currentColor;
  // fill: currentColor;
}

a {
  text-decoration: none;
  color: inherit;
}

#app-header {
  display: block;

  a:not(.button) {
    text-decoration: none;
    color: inherit;
  }
}

button {
  border: none;
}

img {
  display: block;
  pointer-events: none;
  user-select: none;
  transition: opacity 500ms;
  aspect-ratio: attr(width) / attr(height);
}

img.fadein {
  opacity: 0;

  &.loaded {
    opacity: 1;
  }
}

wm-form-error,
::placeholder {
  user-select: none;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: var(--space-x);

  & > * {
    flex: 1 0 auto;
    min-width: var(--wm-col-min, 15.625rem);
  }
}

.global-notify {
  .content {
    .text,
    p,
    div {
      margin: 0 !important;
      line-height: 1.15 !important;
    }
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mat-drawer-content {
  overflow: hidden;
}

img {
  max-width: 100%;
}

main {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: var(--h-font-family);
    font-weight: var(--h-font-weight);
    text-transform: none;
    letter-spacing: 0.0625rem;
  }

  > * {
    position: relative;
    z-index: 0;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  line-height: 1.25;
}

// .align-center {

//   h1,
//   .h1,
//   h2,
//   .h2,
//   h3,
//   .h3 {
//     margin-left: auto;
//     margin-right: auto;
//   }
// }

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

// .navigation-client {
//   router-outlet + * {
//     animation: fade 0.5s;
//   }
// }

ol.gjs-row-inner {
  counter-reset: steps;
}

.gjs-list-item::before {
  font-family: var(--h-font-family);
  font-weight: var(--h-font-weight);
  font-size: 4.5rem;
}

.gjs-row-inner {
  width: 100%;
  max-width: 85.25rem;
}

mat-sidenav,
wm-footer,
.mat-menu-content {
  user-select: none;
  background-color: #fff;
  font-size: 0.75rem;

  p {
    font-size: 1.375rem;
    letter-spacing: 0.125rem;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-size: 1rem;
    font-weight: 600;
  }

  a:not(.button) {
    text-decoration: none;
    color: inherit;
  }

  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
  }
}

.mat-menu-content {
  li {
    margin-bottom: 0;
  }
}

mat-sidenav {
  a {
    padding: var(--space-y) calc(2 * var(--space-x));
    font-weight: 600;
    font-size: 0.875rem;
    display: inline-flex;
    align-items: center;
  }
}

.mat-drawer-container {
  background-color: #fff;
}

wm-header {
  ul,
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    display: flex;
  }

  li {
    // margin-right: var(--space-button);
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

li {
  :where(.button, .button-ghost):not(.button-icon) {
    text-align: left;
    justify-content: flex-start;
  }
}

wm-header,
mat-sidenav {
  .button,
  .button-ghost {
    // font-weight: 300;
    &:focus {
      outline: none;
    }

    &.is-active {
      text-decoration: underline;
      color: var(--color-accent);
    }
  }
}

// .mat-drawer-backdrop.mat-drawer-shown {
//   background: rgb(255 255 255 / 60%);
// }

wm-footer {
  .button,
  .button-ghost {
    font-size: 0.75rem;
    padding: var(--space-button) var(--space-button);
  }

  .v-menu li,
  li {
    max-inline-size: none;
    margin-left: calc(-1 * var(--space-button));
    margin-right: calc(-1 * var(--space-button));

    a {
      width: 100%;
    }
  }
}

// .box-padded {
//   padding: calc(6 * var(--space-y)) var(--space-x);
//   @media (max-width: 68.6875rem) {
//     padding: calc(2 * var(--space-y)) var(--space-x);
//   }
// }

nnm-enter-email {
  .error-p {
    margin: 0;
  }
}

.align-center {
  text-align: center;
  @media (max-width: 992px) {
    text-align: left;
  }
}

.align-flex-start {
  text-align: left;
}

.align-flex-end {
  text-align: right;
}

nnm-rich-text {
  a:not(.button) {
    text-decoration: underline;
    color: var(--color-accent);
  }
}

.tooltip-success {
  background: var(--color-accent);
  color: var(--color-accent-contrast);
}

mat-sidenav {
  min-width: 15rem;
}

label {
  text-transform: uppercase;
}

address {
  font-style: normal;
}

p {
  max-width: 100%;
}

.align-items-center {
  align-items: center;
}

body .mat-expansion-panel-header {
  font: inherit;
  height: auto;
}

mat-panel-title {
  font-family: inherit;
  font-weight: bold;
  font-size: 1.25rem;
  padding: var(--space-y) 0;
}

wm-plans {
  ul,
  ol {
    padding: 0;
  }

  li.not-included .text-strikethrough {
    text-decoration: none;
  }
}

// hr {
//   min-width: 100%;
// }
hr {
  flex: 1 0 auto;
}

nnm-templates-description wm-media {
  align-self: flex-end;
  margin-bottom: calc(-1 * var(--space-row-y));
}

slate {
  line-height: var(--line-height, 1.75);

  .button-row {
    margin-top: var(--space-y);
    margin-left: calc(-1 * var(--space-button));
  }

  ul,
  ol {
    margin-bottom: 1em;
  }
}

// form {
//   @include utils.centralize("stretch");
//   flex: 0 1 auto;
//   gap: var(--space-y) 0;
// }

// .box {
//   background: #fff;
//   box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.15);
//   h2 {
//     text-align: center;
//     font-size: 4rem;
//     margin-bottom: 0;
//   }

//   p {
//     text-align: center;
//   }
// }

// .box {
//   @include utils.centralize;
//   max-width: 41.875rem;
//   flex: 0 1 auto;
//   gap: var(--space-y) var(--space-x);
//   form {
//     max-width: 25.3125rem;
//   }
// }

// .under-header > *:first-child {
//   margin: 4rem 0;
// }

.mb-btn {
  margin-bottom: calc(
    (2 * var(--space-button)) + (var(--button-line-height) * 1em)
  );
}

p {
  white-space: pre-line;
}

.fields {
  display: flex;
  flex-wrap: wrap;
  gap: 0 var(--space-x);
  width: 100%;

  > * {
    flex: 1;
  }
}

.mat-menu-content .button {
  text-align: left;
  justify-content: flex-start;
}

body .wm-field-appearance-standard .wm-field-flex {
  padding-top: 0;
}

mat-sidenav-content {
  padding: var(--viewport-border-width, 0rem) 0;
  // will-change: filter;
  // filter: blur(0rem);
  // transition: filter 250ms;
}

// .mat-drawer-container-has-open {
//   mat-sidenav-content {
//     filter: blur(0.0625rem);
//   }
// }

.frame {
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: var(--viewport-border-width, 0rem) solid #fff;
  pointer-events: none;
}

main > *:not(router-outlet):not(wm-dynamic-loader) {
  margin-top: calc(
    -1 * var(--header-height) - var(--viewport-border-width, 0rem)
  );
  padding-top: calc(
    var(--space-row-y) + var(--header-height) +
      var(--viewport-border-width, 0rem)
  );
  padding-bottom: var(--space-row-y);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-primary);
}

body {
  .mat-horizontal-stepper-wrapper,
  .mat-horizontal-stepper-content,
  .mat-horizontal-content-container {
    flex: 1;
    height: 100%;
  }

  .mat-horizontal-stepper-content,
  .mat-horizontal-stepper-wrapper {
    display: flex;
    flex-direction: column;
  }
}

.color-success {
  color: var(--color-success);
}

nnm-accordion + nnm-heading {
  margin-top: var(--space-row-y);
}

wm-media-text {
  ul {
    font-size: 1.1em;
  }

  li {
    margin-bottom: 0.5em;
  }
}

.w-70ch {
  --container: 43rem;
}

.w-43 {
  --container: 43rem;
}

nnm-heading {
  .text {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 74.9375rem) {
  .desktop-only {
    display: none !important;
  }
}

// (orientation: landscape) and (min-width: 62.0625rem),
@media (min-width: 75rem) {
  .mobile-only {
    display: none !important;
  }
}
